import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerPostProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerPost = styled.section`
  position: relative;
  height: 100%;
  z-index: 1;
  min-height: 434px;
  margin-bottom: -5rem;
  &:before {
    content: '';
    height: 100%;
    min-height: 434px;
    width: 130%;
    position: absolute;
    z-index: -1;
    left: -15%;
    top: 0;
    @media (min-width: 991px) {
      border-radius: 0 0 50% 50%;
    }
    background: ${({ theme }) => theme.color.primary};
  }
`

const BannerWrapper = styled.div`
  position: relative;
  min-height: 434px;
  @media (max-width: 991px) {
    padding-bottom: 2.8rem;
  }
`

const Image = styled(Plaatjie)`
  width: 300px;
  height: 300px;
  margin-bottom: -3rem;
  @media (max-width: 991px) {
    width: 150px;
    height: 150px;
    margin-bottom: 0.8rem;
  }
  @media (max-width: 575px) {
    width: 130px;
    height: 130px;
  }
  @media (max-width: 375px) {
    width: 100px;
    height: 100px;
    margin-top: 0.8rem;
  }
`

const Row = styled.div`
  padding-top: 7rem;
  @media (min-width: 992px) {
    padding-bottom: 6rem;
  }
`

const Content = styled(ParseContent)`
  & p {
    font-size: 14px;
    margin-bottom: 1rem;
    font-weight: ${({ theme }) => theme.font.weight.ultraLight};
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.font.size.huge} !important;
    }
  }
`

const BannerPost: React.FC<BannerPostProps> = ({ fields }) => (
  <StyledBannerPost>
    <BannerWrapper>
      <div className="container h-100">
        <Row className="row justify-content-center align-items-end h-100">
          <div className="col-xl-11">
            <div className="row">
              <div className="col-sm-4">
                <Image image={fields.image} alt="" className="rounded-circle" />
              </div>
              <div className="col-sm-8 d-flex align-items-center">
                <Content
                  content={fields.description}
                  className="ps-sm-5 mb-4"
                />
              </div>
            </div>
          </div>
        </Row>
      </div>
    </BannerWrapper>
  </StyledBannerPost>
)

export default BannerPost
